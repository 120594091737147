import React from "react"
import * as PropTypes from "prop-types"
import { Link, graphql } from 'gatsby'
import Helmet from 'react-helmet'
import { rhythm } from "../../utils/typography"
import Layout from "../../components/layout"

const propTypes = {
  data: PropTypes.object.isRequired,
}

function getLangDir(locale) {
  const langDir = locale === 'ja' ? 'ja' : 'en';
  return langDir;
}

class TermsPage extends React.Component {
  render() {
    //const enNewsEdges = this.props.data.en.edges
    return (
      <Layout data={this.props.data} location={this.props.location}>
        <Helmet>
          <title>利用規約 │ Cookin’Burger</title>
          <meta name="description" content="New PlayMining Platform Game! Set to launch in Spring 2022! A multi-tasking cooking game!" />
        </Helmet>
        <div className="l-main">
          <div className="l-term-section">
            <div className="section-inner_m">
              <div className="l-term-section-box">
                <div className="l-term-section-title wow fadeInUp" data-wow-delay="0.2s" data-wow-duration="0.7s" data-wow-offset="0">
                利用規約
                </div>
                <p className="l-term-section-cap wow fadeInUp" data-wow-delay="0.3s" data-wow-duration="0.7s" data-wow-offset="0">
                  <div className="terms-title">1. 目的</div>
                    本規約は、オンラインゲームプラットフォームPlayMining（関連するメディアチャネル、モバイルウェブサービスまたはモバイルアプリケーションを含み、以下総称して「本サービス」といいます）上にて提供されるオンラインゲーム（以下「本ゲーム」といいます）の利用、または本サービスに関連して利用できるすべてのサービス、機能、コンテンツに対するアクセスおよび使用を規定する規約を定めるものです。なお、本サービスの運営者及び本ゲームの運営者を総称して「本運営者」といいます。<br /><br />
                    本サービスを利用する個人（以下「利用者」といいます）は、本ゲームを利用することにより、本規約に同意し、かつ、プライバシーポリシーに定めるデータの収集および処理に同意するものとみなします。利用者が本規約またはプライバシーポリシーの条項に同意しない場合、利用者は本ゲームにアクセスまたは使用することができません。また、利用者は、本サービスにアクセスまたは使用する際に、本運営者が随時変更する本サービスの特定の部分に適用される追加のガイドラインまたは規則の適用を受ける場合があります。これらのガイドラインや規則はすべて、本規約に組み込まれるものとします。<br />
                      <br />
                      <div className="terms-title">2. 定義</div>
                      本規約において、以下の語句は、以下に定める意味を有するものとします。<br />
                      「アカウント」：本サービスを利用するために利用者が開設するPlayMining IDアカウントをいいます。<br />
                      「ゲームデータ」：利用者による本ゲームの利用の履歴、ゲーム内において発生した一切の情報（キャラクター、取得したアイテム、入手したゲーム内通貨等を含みます）をいいます。<br />
                      「ゲーム内通貨」：本ゲーム内において発行及び消費されるゲーム内通貨をいいます。<br />
                      「NFT」：ブロックチェーンで生成された非代替性トークンをいいます。<br />
                      「PlayMining NFT」：本運営者が運営するオンライン・オークション・サービスをいいます。<br />
                      「DEAPcoin」：本ゲーム上での一部アイテム購入や、PlayMining NFT上でのNFTの売買等に利用可能な暗号資産をいいます。<br />
                      「PlayMining ID」：本運営者所定の方法により別途開設するIDをいいます。<br />
                      「即決販売」：売主と買主との相対取引の方法によってNFTを売買する方法をいいます。<br />
                      <br />
                      <div className="terms-title">3. 本ゲームの利用</div>
                      本ゲームを利用（本ゲームのゲームデータを保存し、継続して利用することを含みます）するためにはPlayMining IDの登録が必要となります。<br />
                      <br />
                      <div className="terms-title">4. アカウントの登録</div>
                      本サービスの利用には、アカウント登録が必要となります。利用者は本運営者所定の登録事項を、本運営者所定の方法で登録することでアカウント登録（PlayMining IDの登録を含みます）を行うものとします。利用者は、登録時点での最新かつ正確な情報で登録するものとします。利用者が、最新かつ正確な情報を提供できない場合には、本運営者がアカウントの登録を拒否する場合があります。<br />
                      <br />
                      利用者は、本サービスの利用により成年であることを表明し、保証します。利用者が未成年の場合には、親権者等法定代理人の同意を得た上で登録するものとし、法定代理人の同意を得たことを表明し、保証します。<br />
                      アカウント登録後に、法定代理人の同意がないことが明らかになった場合には、本運営者は、当該アカウントを停止または抹消することが出来、現在及び将来の本サービスの一部または全部の提供を拒否することが出来ます。<br />
                      <br />
                      利用者はアカウント登録後に、登録事項の変更があった場合には、速やかに本運営者に通知するものとします。<br />
                      本運営者への通知を怠ったことにより、利用者が損害を受けた場合、本運営者は本運営者に故意または重大な過失がある場合を除き、利用者が被った損害について一切の責任を負いません。<br />
                      <br />
                      <div className="terms-title">5. アカウントの管理</div>
                      利用者は、アカウントを厳重に管理し、本運営社が不当であるものと判断する態様での利用をしてはならないものとします。なお、利用者は、不当な態様での利用として、第三者に対して当該アカウントの安全性を損なう態様での開示をしてはらないないほか、貸与、譲渡、売買、質入等もしてはならないものとしますが、これに限られないものとします。<br />
                      <br />
                      アカウントを利用した本サービスの利用は、当該アカウントの名義人である利用者自身の行為とみなされるものとし、当該アカウントを利用することにより生じた一切の債務は、利用者自身が負担するものとします。<br />
                      <br />
                      本運営者は、本運営者に故意または重大な過失がある場合を除き、利用者が被った損害について一切の責任を負いません。<br />
                      <br />
                      <div className="terms-title">6. アカウントの停止・制限・抹消</div>
                      本運営者は、利用者が以下の事由のいずれか一つにでも該当すると判断した場合、利用者のアカウントの停止、制限または抹消を行うことができるものとします。<br />
                      (1) 利用者またはアカウントが第16条の禁止事項に該当する行為を行った場合、またはその他本規約に違反する行為を行った場合。プライバシーポリシーまたは既存の法律、規則および規制違反する行為を行った場合<br />
                      (2) 利用者またはアカウントが、政府による手続き、犯罪捜査、訴訟等の対象となる場合<br />
                      (3) 利用者による本サービスの利用が、違法、不法または禁止されている活動に関連している場合<br />
                      (4) 第三者によるアカウントの利用が判明した場合<br />
                      (5) 利用者が本規約に従った支払を行わなかった場合<br />
                      (6) 破産、民事再生手続開始の申立があった場合<br />
                      (7) 決済事業者により、決済が停止または無効扱いとされた場合<br />
                      (8) その他利用者による本サイトの利用を継続することが不適切であると本運営者が判断する事象が発生した場合<br />
                      本運営者は、利用者のアカウントの停止、制限または抹消により利用者に損害が発生した場合においても、本運営者に故意または重大な過失がある場合を除き、利用者が被った損害について一切の責任を負いません。<br />
                      <br />
                      <div className="terms-title">7. ゲームデータ</div>
                      利用者は、本ゲームのゲームデータを、本運営者が定める目的の範囲内に限り、利用することができ、目的外の利用（本運営者が定めていない用法による場合にも、本運営者が社会通念上不合理であるものと判断した態様による利用方法を含みます）をすることはできません。<br />
                      <br />
                      利用者は、ゲームデータを本運営者が合理的であるものと判断する範囲を超えて利用することができず、第三者に譲渡または利用させることはできません。<br />
                      本運営者は、以下の事由に該当する場合、利用者のゲームデータを削除、移動または変更することができます。<br />
                      (1) ゲームデータに対応するゲームタイトルの提供が終了した場合<br />
                      (2) ゲームデータに対応するゲームタイトルの運営を第三者に移管する場合<br />
                      (3) 利用者が本規約等に違反した場合（ゲームデータの内容または作成過程が本規約等に違反するものであると本運営者が判断した場合を含みます）<br />
                      (4) ゲームデータが所定の容量を超える等技術的な理由により必要と認めた場合<br />
                      (5) その他本運営者が必要と判断した場合<br />
                      <br />
                      本運営者は、利用者に対し、ゲームデータを変更する義務を負いません。<br />
                      <br />
                      <div className="terms-title">8. ゲーム内通貨の入手</div>
                      利用者は、ゲーム内の行動（ログインボーナスや戦闘の報酬など）により、ゲーム内通貨を入手するものとします。<br />
                      利用者は、本運営者の独自の裁量で課された制限、または要件に従ってのみゲーム内通貨を取得します。<br />
                      <br />
                      <div className="terms-title">9. ゲーム内通貨の利用</div>
                      利用者は、利用残高の範囲内で、本運営者が定める方法により、ゲーム内通貨を利用し、所定の対応アイテムまたは特別サービスの提供を受けることができます。<br />
                      利用者が対応アイテムの提供を受けた場合、当該対応アイテムの提供を受けるために利用したゲーム内通貨は、消滅します。<br />
                      ゲーム内通貨の利用残高は、PlayMiningIDとともに本運営者のサーバに記録されます。利用者が、同じPlayMiningIDで利用する限りにおいては、当該PlayMiningIDに係るゲーム内通貨を利用することができます。<br />
                      利用者は、自らの責任でゲーム内通貨を管理するものとします。<br />
                      利用者は、ゲーム内通貨を第三者に貸与、売買、贈与、その他移転することができません。<br />
                      利用者は、本運営者がゲーム内通貨の利用に際して一定の制限を設ける場合、当該制限の範囲内でゲーム内通貨を利用するものとします。<br />
                      <br />
                      <div className="terms-title">10. ゲーム内通貨の廃止</div>
                      理由の如何にかかわらず、PlayMining IDが削除された場合、本運営者のサーバに記録されている当該PlayMining IDに係るゲーム内通貨は、消滅します。<br />
                      利用者は、このゲーム内通貨の消滅につき、本運営者に対して何ら請求を行うことができず、本運営者は、消滅したゲーム内通貨について何らの補償も行わず、一切の責任を負いません。ただし、本運営者の故意または重大な過失によりゲーム内通貨が消滅した場合は、この限りではありません。<br />
                      <br />
                      <div className="terms-title">11. DEAPcoinの利用</div>
                      利用者は、保有残高の範囲内で、本運営者が定める方法により、DEAPcoinを利用し、一部アイテムの提供または特別サービスの提供を受けることができます（以下総称して「DEAPcoin利用サービス」といいます）。<br />
                      利用者がDEAPcoin利用サービスの提供を受けた場合、当該サービスを受けるために利用したDEAPcoinは、Walletの保有残高から引き落とされます。<br />
                      DEAPcoinの保有残高は、利用者のアカウントとともに本運営者のサーバに記録されます。利用者が、同じアカウントで利用する限りにおいては、当該アカウントに係るWallet内のDEAPcoinを利用することができます。<br />
                      利用者は、自らの責任でDEAPcoinを管理するものとします。<br />
                      本運営者は理由の如何を問わず、DEAPcoinの利用の取消しおよび撤回、ならびにDEAPcoinと現金その他仮想通貨との交換はしません。<br />
                      利用者は、本ゲーム上、またはPlayMining NFTでDEAPcoinを第三者に貸与、売買、贈与、その他移転することができません。<br />
                      利用者は、本運営者がDEAPcoinの利用に際して一定の制限を設ける場合、当該制限の範囲内でDEAPcoinを利用するものとします。<br />
                      <br />
                      <div className="terms-title">12. スカラーシップ</div>
                      スカラーシップは、利用者間で、NFTの貸し借りを行う制度をいいます（貸出を行う利用者を「Owner」、借り受ける利用者を「Scholar」といいます。なお、当該貸し借りを行なっている期間を「スカラーシップ期間」といいます。）。<br />
                      スカラーシップ期間中のScholarに関する情報、Scholarが獲得したゲーム内アイテム等のうち、OwnerとScholarが事前にOwnerに送付することに同意した情報、ゲーム内アイテム等は、自動的にOwnerに送付されます。Ownerは、スカラーシップ期間中、対応するNFTを本サービス上の取引において利用（出品を含みます）することができません。
                      本運営者は、スカラーシップの実施に関し一切関与せず、何らの補償も行わず、一切の責任を負いません。<br />
                      <br />
                      <div className="terms-title">13. 料金</div>
                      本ゲームの利用は別段の定めのない限り、利用者向けに無料で提供されますが、利用者はゲーム内アイテムをDEAPcoinまたは本運営者の提携する決済代行業者の提供する決済手段により購入することができるものとします。<br />
                      利用者は、本運営者が、あらゆるハードウェア、ソフトウェア、通信等と本ゲームの接続について、一切の責任を負わないことに同意します。利用者は、本ゲームの利用に必要となる通信設備や通信料等は利用者の負担とすることに同意します。<br />
                      <br />
                      <div className="terms-title">14. 権利帰属</div>
                      本ゲームのすべての知的財産権（全てのソースコード、データベース、機能、ソフトウェア、ウェブサイトのデザイン、オーディオ、ビデオ、テキスト、写真、グラフィックを含む）およびそこに含まれる商標、サービスマーク、およびロゴ（以下「マーク」といいます）は、本運営者が所有、管理、またはライセンスを受けています。利用者は、本利用規約に従ってそれらを使用する権利を除き、本ゲーム内または本ゲームに対する知的財産権を有しないものとします。<br />
                      <br />
                      利用者は、本運営者が別途定める方法によることなく、本ゲームおよび本ゲームを提供するWebサイトに関する画像、動画、音声、ロゴ、文章、プログラム等各種コンテンツを複製、譲渡、貸与、翻訳、変更、転載、再利用または第三者への使用許諾等いかなる態様においても使用できないものとします。<br />
                      <br />
                      <div className="terms-title">15. 個人情報</div>
                      本運営者は、本サービスの提供に関連して利用者から取得した個人情報を、本運営者のプライバシーポリシーに基づき適切に取り扱うとともに、個人情報の適切な保護と管理に努めます。<br />
                      利用者は、偽りその他不正の手段により取得した個人情報を、本サービス上で使用しないものとします。<br />
                      <br />
                      <div className="terms-title">16. 禁止事項</div>
                      本運営者は、利用者が本ゲームの利用に関連し、以下の事項を行うことを禁止します。<br />
                      (1) 他の利用者に対して誹謗中傷、脅迫、不当な要求等を行うことや、性的、差別的、反社会的その他の不快感を与える行為や発言等を行うことにより、不安感や不快感を与えること<br />
                      (2) 本運営者に対して過度な問い合わせや誹謗中傷、脅迫、不当な要求を行う等本ゲームの運営または本運営者の業務に支障をきたす行為を行うこと<br />
                      (3) 本運営者関係者、他の利用者、その他第三者または架空の人物を装い、これらになりすます行為<br />
                      (4) 第三者を誹謗中傷する等第三者の名誉または信用を毀損すること<br />
                      (5) 本ゲームと無関係な宣伝、商取引、物品の交換、宗教活動、勧誘等<br />
                      (6) 犯罪行為、不法行為その他の国内外の法令に抵触する行為（第三者の権利を不当に害する行為を含む）<br />
                      (7) 公序良俗に反する行為<br />
                      (8) 宗教、人種、性別、民族、人権等に関する差別、偏見に基づく行為<br />
                      (9) 違法または有害な活動や物質を推奨したり、参考となる情報を提供したりする行為<br />
                      (10) 自殺、自傷に関する勧誘や情報の提供をする行為<br />
                      (11) わいせつ、暴行、犯罪、児童ポルノまたは児童虐待にかかる情報の発信またはこれらを記録した媒体を頒布する行為<br />
                      (12) 違法または有害な活動や物質を推奨したり、参考となる情報を提供すること<br />
                      (13) 未成年者の健全な育成に悪影響を与える行為または支障をきたす行為<br />
                      (14) 他の利用者その他第三者の個人情報を収集、蓄積、開示等する行為<br />
                      (15)本サービスを、第三者の氏名やemailアドレスを電磁的手段によるか否かを問わず収集しemailを送信する、機械的方法によりアカウントを作成する、または虚偽のアカウントを作成するといった、本来の目的外の目的で利用する行為<br />
                      (16) PlayMining IDおよびゲームデータの全部または一部を貸与、交換、譲渡、名義変更、売買、質入、担保その他の方法により第三者に利用させまたは処分する行為 (17) 複数のPlayMining IDを保有する行為。但し、事前に本運営者の許諾を得た場合を除く<br />
                      (18) 未成年者が本ゲームを利用すること。成人年齢については、利用者の居住国の法律に準ずるものとする。但し、居住国の法律に抵触しないことを前提に、本運営者が事前に許諾した場合を除く<br />
                      (19) 本運営者のネットワークまたはサービスを悪用または妨害する目的で、本ゲームを使用する行為<br />
                      (20) 本運営者が実装する技術的手段またはセキュリティ関連機能（情報およびデータの使用、コピーを防止または制限する機能を含む）を回避、無効化、その他の方法で妨害または妨害を試みる行為、ゲームおよびゲームに含まれるデータの使用に制限を課す行為<br />
                      (21) 本運営者からの書面による許可なしに、ゲームからデータおよびその他のコンテンツを体系的に取得する行為（コンパイル、データベース化、およびディレクトリを直接的または間接的に作成することを含む）<br />
                      (22) データマイニング、スクレイピング、ロボット、または同様のデータ収集または抽出方法の使用行為<br />
                      (23) ウイルス、トロイの木馬、マルウェア、破損したデータ、その他の有害なファイルをアップロードまたは送信（またはアップロードまたは送信の試みを）することにより、本ゲームの中断のない使用および本ゲームの妨害、または改変、妨げ、破壊し、本ゲームの使用、特徴、機能、操作、またはメンテナンスを変更または妨害する行為<br />
                      (24) 本運営者がゲームを操作することを意図していないアプリケーションまたはソフトウェアを開発または使用する行為<br />
                      (25) 本ゲームの逆コンパイル、逆アセンブル、リバースエンジニアリング等による解析行為<br />
                      (26) ゲーム内のデータや機能を模倣したデータまたは実行可能なプログラムを作成すること<br />
                      (27) 本ゲームの全部または一部を複製する等して再頒布、再販売、貸与、転売を行う行為<br />
                      (28) 上記に定める行為を準備、教唆、ほう助、支援、助長または喧伝する行為（不作為も含む）<br />
                      (29) その他本規約等または法令等の定めに違反する行為<br />
                      (30) 上記に定める行為に類するまたは抵触するおそれがあると認められる行為<br />
                      (31) その他本運営者が不適切と判断する行為<br />
                      <br />
                      <div className="terms-title">17. 本ゲームの変更・中断・終了</div>
                      本運営者は、独自の判断により、いつでも、理由の如何を問わず、利用者への通知の有無にかかわらず、本サービス（またはその一部）を一時的または永久に変更、停止または中止する権利を留保します。利用者は、本サービスの変更について本運営者が利用者に対しいかなる責任も負わないこと、および本サービス上のいかなる情報についても本運営者が更新する義務を負わないことに同意するものとします。<br />
                      <br />
                      本運営者は、定期的または不定期に、機器、設備、ソフトウェア、ハードウェア等の不具合や本サービスに関するメンテナンスが必要な場合、保守・整備を行うことがあります。利用者は、本サービスの停止または中断の間、利用者が本サービスにアクセスまたは使用できなかったことにより生じたいかなる損失、損害または不都合についても、本運営者が一切の責任を負わないことを理解し、これに同意するものとします。本運営者は、緊急の場合または本運営者の責によらない予期せぬ事態により停止または終了する場合を除き、本運営者が適切と考える方法にて、利用者に事前に通知するものとします。<br />
                      <br />
                      <div className="terms-title">18. 税金と手数料</div>
                      利用者は、本サービスに関連して、または本サービスの利用を理由として、異なる法域によって課されるあらゆる種類の為替手数料、第三者手数料、売上、使用、付加価値、動産その他の料金、税金、関税または徴税（それに対する利息および罰則を含み、現在または将来の政府機関料金によって課されるか否かを問わない）を支払う責任を単独で負うものとします。<br />
                      <br />
                      <div className="terms-title">19. 保証の否認</div>
                      本ゲームは、現状有姿で提供するものとし、利用者は自己の責任で本ゲームを利用するものとします。法律上許容された範囲において、本運営者は、本ゲーム及び本ゲームの利用に関し、明示又は黙示の商品性の適合性の黙示の保証、特定の目的への適合性及び以下の事項を含むいかなる保証をもしません。<br />
                      (1) 本ゲームの安定的な稼働<br />
                      (2) 本ゲームにバグその他の瑕疵がないこと<br />
                      (3) 本ゲームの完全性および確実性<br />
                      (4) 本ゲームが特定の機器で利用可能であること<br />
                      (5) 本ゲームの利用により特定の機器に問題が発生しないこと<br />
                      (6) 本ゲームの内容および本ゲームの利用に関連して利用者が得る一切の情報の完全性、正確性、有用性等<br />
                      (7) 本ゲームのゲームデータが完全に保持されること<br />
                      (8) 本ゲームが第三者の知的財産権等その他一切の権利を侵害していないこと<br />
                      <br />
                      <div className="terms-title">20. 損害賠償</div>
                      利用者は、（A）本サービスまたは本ゲームの利用、（B）本規約の違反（C）その他利用者自身に起因する事由により本運営者に損害を与えた場合、本運営者に対し、当該損害の一切を賠償するものとし、第三者から本運営者への請求に対し、本運営者を防御し、補償し、免責するものとします。<br />
                      <br />
                      <div className="terms-title">21. 責任制限</div>
                      本運営者は、本運営者に故意または重大な過失がある場合の直接損害を除き、利用者が受けた損害（間接損害、派生損害、結果的損害、懲罰的損害賠償、法定損害賠償、または特別損害を含み、逸失利益、売り上げの減少、データの喪失や改ざん、ゲーム内通貨の喪失又は廃止、その他いかなる損害賠償原因（契約法、不法行為、免責補償、保証違反）に基づくかを問わず）について一切の責任を負いません。これは、本運営者が事前にその可能性について助言を受けていたかに関わりません。<br />
                      <br />
                      本運営者は、本サービス及び本ゲームに関し、（1）コンテンツや内容の正確性、（2）利用者による本ゲームへのアクセス、（3）サーバー環境、(4)個人情報または個人の財産に関わる情報への不正アクセス及びこれに付随する不正な使用、（5）本ゲームの通信障害またはこれに類する事象、（6）ウイルスまたはサイバー攻撃、（7）コンテンツおよび付随する内容に関するエラー、(8)その他社会通念上本運営者の責に帰することのできない事由については一切保証しないものとし、当該事由に起因して発生した損害（利用者に発生した損害を含むがこれに限られない）について、いかなる責任も負わないものとします。<br />
                      <br />
                      <div className="terms-title">22. 本規約の変更</div>
                      本運営者は、本規約およびプライバシーポリシーの一部を随時変更、修正、追加または削除する権利を留保します。本運営者は、本規約およびプライバシーポリシーを変更、修正、追加または削除する場合、本サービス、本ゲームの公式サイトまたは本ゲーム内を通じて、または本運営者が指定するその他の方法で通知することができるものとします。変更後の利用規約およびプライバシーポリシーは、当該通知において別段の定めがない限り、直ちに効力を生じるものとします。利用者は、通知後、本サービスへのアクセスおよび使用を継続することにより、変更後の利用規約およびプライバシーポリシーに同意したものとみなされます。利用者が変更後の利用規約および プライバシーポリシーに同意しない場合、利用者は本サービスへのアクセスまたは使用を直ちに停止しなければなりません。<br />
                      <br />
                      <div className="terms-title">23. 準拠法および紛争解決</div>
                      本規約に定める条件は、シンガポール共和国の法律に従って解釈されるものとします。本規約または本サービスに起因または関連するあらゆる紛争、訴訟、請求または訴訟原因（その存在、有効性または終了に関するあらゆる問題を含む）は、シンガポール国際仲裁センター（以下「SIAC」といいます）が管理する仲裁に付託され、最終的には、随時修正または改正されるSIACの仲裁規則（以下「SIAC規則」といいます）に従って、当事者双方の合意により指名される単独の仲裁人（以下「仲裁人」といいます）により解決されるものとします。当事者が仲裁人について合意できない場合、仲裁人は、SIAC規則に従って、SIAC所長が任命するものとします。仲裁の裁判地及び開催地はシンガポール、仲裁の言語は英語、仲裁人の費用は当事者が均等に負担するものとします。ただし、仲裁人は、この仲裁条項が適用法の下で執行可能であるために必要であると仲裁人が判断する他の方法で、かかる費用の負担を求めることができるものとします。<br />
                      <br />
                      一般条項<br />
                      (1) 累積的な権利と救済措置：本規約に別段の定めがない限り、本規約の規定および本規約に基づく本運営者の権利と救済措置は累積的であり、本運営者が法律上または衡平法上有するあらゆる権利または救済措置を損なうことなく、これに追加されるものです。また、本運営者が本規約に基づく、または法律上もしくは衡平法上の権利または救済措置を行使することは、（本規約または法律上もしくは衡平法上の権利または救済措置に明示的に規定されている場合を除き）本運営者が法律上または衡平法上の他の権利または救済措置を行使することを妨げたり阻止したりするように作用しないものとします。<br />
                      (2) 権利放棄の不存在：本運営者が本規約のいかなる権利または規定を行使しなかったとしても、本運営者が書面により承認し同意した場合を除き、かかる権利または規定を放棄したことにはなりません。<br />
                      (3) 分離可能性：本規約のいずれかの条項が無効または執行不能と判断された場合、当該条項は削除され、残りの条項は法律の下で最大限の範囲で執行されるものとします。このことは、本規約に定める準拠法及び裁判管轄についても、同様に制限なく適用されるものとします。<br />
                      (4) 第三者の権利：本規約の当事者でない個人または事業体は、シンガポールの契約法第53B章またはあらゆる法域の類似の法律に基づき、本規約のいかなる条項を行使する権利も有さないものとします。疑問を回避するために、本款のいかなる内容も、本規約の許可された譲受人または譲受人の権利に影響を及ぼさないものとします。<br />
                      (5) 差止命令による救済：本運営者は、違反または不履行が、一時的な差止命令またはその他の即時の差止命令による救済が唯一の適切または十分な救済であると誠実に判断した場合、即時の差止命令による救済を求めることができるものとします。<br />
                      (6) 誤りの訂正：本運営者による検収書、請求書、その他の文書における誤字、脱字は、本運営者によるいかなる責任も負うことなく訂正されるものとします。<br />
                      (7) 完全なる合意：本規約は、本規約の主題に関する当事者間の完全な合意を構成し、当該主題に関する当事者間の従前のすべての理解、やりとりおよび合意に取って代わり、完全なものとなります。<br />
                      (8) 譲渡：本運営者は、いつでも、その権利および義務の一部または全部を第三者に譲渡することができます。<br />
                      (9) パートナーシップ等の不存在：本規約または本サービスの利用により、利用者と本運営者または第三者との間にジョイントベンチャー、パートナーシップ、雇用、または代理関係は存在しません。<br />
                      (10)不可抗力：本運営者は、本規約（またはその一部）に基づく本運営者の義務の不履行、エラー、中断、遅延、または本サービスの内容の不正確性、信頼性、不適合について、その全部または一部が、本運営者の合理的支配を超える事象または故障に起因する場合、責任を負わないものとします。<br />
                      (11) 終了：利用者は、利用者が本規約に定めるいずれかの条項に違反したことが判明した場合、本運営者が本規約を直ちに終了させる権利を有することに同意するものとします。<br />
                      なお、本利用規約の終了に伴い、PlayMining NFTへのアクセスやサービスの利用により利用者に発生した費用について、本運営者が補償、弁済、または負担する必要はないものとします。<br />
                      <br />
                      <div className="terms-title">24. NFTの参照</div>
                      利用者は、PlayMining NFTで購入したNFTを、本ゲーム内で、本運営者所定の方法により参照することができます。<br />
                      NFTの売買その他の詳細な条件については、別途定める PlayMining NFTの利用規約によるものとします。<br />
                      <br />
                </p>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

TermsPage.propTypes = propTypes

export default TermsPage

export const pageQuery = graphql`
  query TermsJaQuery {
    site {
      siteMetadata {
        languages {
          defaultLangKey
          langs
        }
      }
    }
  }
`